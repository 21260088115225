<template>
  <div class="container" v-loading="loading">
    <div v-if="!user_email" class="emailc">
      <div class="tip">
        Please input your email to add the into your personal AI knowledge base
        and receive periodic reminders of its key takeaways via emails.
      </div>
      <el-input
        v-model="email"
        placeholder="Please input your email"
      ></el-input>
      <div style="margin-top: 24px; text-align: center">
        <el-button type="primary" @click="submit">Submit</el-button>
      </div>
    </div>
    <div v-else-if="user_email && success" class="emailc">
      <div class="tip">
        Successful! You have added {{ article.title }} into your personal AI
        knowledge base and receive periodic reminders of its key takeaways via
        emails.
        <div style="margin-top: 24px; text-align: center">
          <el-button type="primary" @click="doLogin" style="margin-top: 24px"
            >Login</el-button
          >
        </div>
      </div>
    </div>
    <div v-else class="emailc">
      <div class="tip">
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script>
import { getUserEmail } from "../utils/store";
import {
  submitUser,
  archiveArticleAndReimind,
  getArticle,
  createLoginPinCode,
} from "../api/reff";
export default {
  data() {
    return {
      user_email: getUserEmail(),
      email: "",
      success: false,
      loading: false,
      article_id: this.$route.query.id,
      message: "",
      article: {},
    };
  },
  mounted() {
    if (this.user_email) {
      this.loading = true;
      submitUser({
        user_email: this.user_email,
      }).then((res) => {
        if (res.data.code === 0) {
          let user_id = res.data.data._id.$id;
          this.archive(user_id);
        } else {
          this.message = "Failed";
          this.loading = false;
        }
      });
    }
  },
  methods: {
    gotoInputPwd() {
      this.$router.push({
        path: "/login/pwd",
        query: {
          email: this.user_email,
        },
      });
    },
    doLogin() {
      createLoginPinCode(this.user_email, "user").then((res) => {
        this.$message.success("OTP sent,Please check your email");
        this.gotoInputPwd();
      });
    },
    getArticle() {
      getArticle({
        article_id: this.article_id,
      }).then((res) => {
        if (res.data.code === 0) {
          this.article = res.data.data;
        }
      });
    },
    submit() {
      let reg = /^(\w-*\.*)+@(\w-?)+(\.\w{2,})+$/;
      if (!reg.test(this.email)) {
        this.$message.error("Please input the correct email format");
        return;
      }
      this.user_email = this.email;
      this.loading = true;
      submitUser({
        user_email: this.email,
      }).then((res) => {
        if (res.data.code === 0) {
          let user_id = res.data.data._id.$id;
          this.archive(user_id);
        } else {
          this.message = "Failed";
          this.loading = false;
        }
      });
    },
    archive(user_id) {
      this.loading = true;
      archiveArticleAndReimind({
        user_id: user_id,
        article_id: this.article_id,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.success = true;
          } else {
            this.message = res.data.msg;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  box-sizing: border-box;
  .emailc {
    line-height: 30px;
  }
  .tip {
    margin-bottom: 20px;
    font-size: 20px;
    color: #333;
  }
}
</style>